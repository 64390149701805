// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-cell {
    max-width: 200px; /* Set maximum width for columns */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/CollectedData.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,kCAAkC;IACpD,mBAAmB,EAAE,qBAAqB;IAC1C,gBAAgB,EAAE,6BAA6B;IAC/C,uBAAuB,EAAE,2CAA2C;EACtE","sourcesContent":[".scrollable-cell {\n    max-width: 200px; /* Set maximum width for columns */\n    white-space: nowrap; /* Prevent wrapping */\n    overflow: hidden; /* Hide overflowing content */\n    text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
